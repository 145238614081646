<template>
  <b-form-datepicker
    required
    value-as-date
    class="date-picker"
    locale="en"
    :date-format-options="{ month: '2-digit', year: 'numeric', day: '2-digit' }"
    :value="date"
    @input="onInput"
  ></b-form-datepicker>
</template>

<script>
function padTo2Digits(val) {
  return String(val).padStart(2, '0')
}

function formatDate(date) {
  const m = padTo2Digits(date.getMonth() + 1)
  const d = padTo2Digits(date.getDate())
  const y = date.getFullYear()

  return [m, d, y].join('/')
}

export default {
  props: ['value'],

  methods: {
    onInput(value) {
      this.$emit('input', formatDate(value))
    },
  },

  computed: {
    date() {
      return new Date(this.value)
    },
  },
}
</script>

<style>
.date-picker .btn {
  background-color: transparent !important;
  width: auto !important;
}
</style>