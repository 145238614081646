<template>
  <div class="container">
    <!--company information C-Coperation-->
    <template v-if="customer.type=='CCORP'">
      <div class="comp-sec">
        <div class="row">
          <div class="col-lg-8 mb-3">
            <label class="required-star">C-Corporation {{ $t('company.name' ) }}</label>
            <input
              type="text"
              class="form-control"
              v-model="customer.companyName"
              placeholder
              value
              required
            />
            <div class="invalid-feedback">Valid company name is required.</div>
          </div>
          <div class="col-lg-4 mb-3">
            <label class="required-star">{{ $t('company.designator') }}</label>
            <select class="custom-select d-block w-100" v-model="customer.designator" required>
              <Option value>{{ $t('message.choose') }}...</Option>
              <Option value="INC.">INC.</Option>
              <Option value="CORP">CORP</Option>
            </select>
            <div class="invalid-feedback">Designator is required.</div>
          </div>
        </div>
        <div class="mb-3">
          <label class="required-star" for="C-industry">{{ $t('company.industry') }}</label>
          <select
            class="custom-select d-block w-100"
            id="C-industry"
            v-model="customer.industry"
            required
          >
            <Option value>{{ $t('message.choose') }}...</Option>
            <Option value="Health care & social assistance">{{ $t('company.healthCare') }}</Option>
            <Option value="Wholesale-agent/broker">{{ $t('company.broker') }}</Option>
            <Option value="Construction">{{ $t('company.construction') }}</Option>
            <Option value="Rental & leasing">{{ $t('company.rental') }}</Option>
            <Option value="Transportation & warehousing">{{ $t('company.transportation') }}</Option>
            <Option value="Accommodation & food service">{{ $t('company.accommodation') }}</Option>
            <Option value="Wholesale-other">{{ $t('company.wholesale') }}</Option>
            <Option value="Retail">{{ $t('company.retail') }}</Option>
            <Option value="Real estate">{{ $t('company.estate') }}</Option>
            <Option value="Manufacturing">{{ $t('company.manufacturing') }}</Option>
            <Option value="Finance & insurance">{{ $t('company.finance') }}</Option>
            <Option value="Others">{{ $t('company.other') }}</Option>
          </select>
          <div v-if="customer.industry === 'Others'" class="pt-3">
            <input
              type="text"
              class="form-control"
              v-model="customer.industryOther"
              placeholder="Specified business type / industry"
              value
              required
            />
          </div>
          <div class="invalid-feedback">Business type is required.</div>
        </div>

        <div class="mb-3">
          <label class="required-star" for="C-purpose">{{ $t('company.purpose') }}</label>
          <textarea
            class="form-control"
            id="C-purpose"
            v-model="customer.purpose"
            required
            rows="3"
            placeholder="i.e. Selling bakery goods"
          ></textarea>
        </div>
      </div>
      <div class="comp-sec">
        <h4>{{ $t('company.addressInformation') }}</h4>
        <p class="text-left-Large" v-show="customer.state==='CA'" v-html="$t('company.california')"></p>
        <p class="text-left-Large" v-show="customer.state==='DE'" v-html="$t('company.delaware')"></p>
        <div class="card-deck mb-3">
          <div
            class="card mb-6 shadow-sm"
            v-bind:class="{ highlight: !customer.useAddressRent }"
            @click="customer.useAddressRent = false"
          >
            <div class="card-header" v-bind:class="{highlightBack: !customer.useAddressRent}">
              <h4 class="my-0 font-weight-normal">{{ $t('company.ownAddress') }}</h4>
            </div>
            <div class="card-body">
              <ul class="list-unstyled mt-3 mb-4 text-left">
                <li>1. {{ $t('company.ownAddress1', { state: $t('message.' + customer.state.toLowerCase()) })}}</li>
                <li v-show="customer.state==='CA'">2. {{ $t('company.ownAddress2InCa') }}</li>
                <li v-show="customer.state==='DE'">2. {{ $t('company.ownAddress2InDe') }}</li>
                <li>3. {{ $t('company.ownAddress3') }}</li>
              </ul>
            </div>
          </div>
          <div
            class="card mb-6 shadow-sm"
            v-bind:class="{ highlight: customer.useAddressRent }"
            @click="customer.useAddressRent = true"
            v-show="customer.state==='CA'"
          >
            <div class="card-header" v-bind:class="{highlightBack: customer.useAddressRent}">
              <h4 class="my-0 font-weight-normal">{{ $t('company.virtualAddress') }}</h4>
            </div>
            <div class="card-body">
              <p>{{ $t('company.virtualAddress1') }}</p>
              <address>
                160 E Tasman Dr
                <br />San Jose, CA, 95134
              </address>
              <h1>
                $ 300
                <small class="extra-small">{{ $t('message.perYear') }}</small>
              </h1>
              <small>{{ $t('company.virtualAddress2') }}</small>
            </div>
          </div>
          <div
            class="card mb-6 shadow-sm"
            v-bind:class="{ highlight: customer.useAddressRent }"
            @click="customer.useAddressRent = true"
            v-show="customer.state==='DE'"
          >
            <div class="card-header" v-bind:class="{highlightBack: customer.useAddressRent}">
              <h4 class="my-0 font-weight-normal">{{ $t('company.virtualAddress') }}</h4>
            </div>
            <div class="card-body">
              <p>{{ $t('company.virtualAddress1') }}</p>
              <address>
                300 Delaware Avenue, Suite 210
                <br />Wilmington, DE, 19801
              </address>
              <h1>
                $ 300
                <small class="extra-small">{{ $t('message.perYear') }}</small>
              </h1>
              <small>{{ $t('company.virtualAddress2') }}</small>
            </div>
          </div>
        </div>
        <div v-if="!customer.useAddressRent">
          <p class="text-left-Large">
            <input type="checkbox" v-model="customer.companyUseContactAddr" />
            {{ $t('company.useContactAddress') }}
          </p>
          <div v-if="customer.companyUseContactAddr">
            <AddressInformation
              :customer="companyAddr"
              address1="companyAddress"
              address2="companyAddress2"
              country="companyCountry"
              state="companyState"
              city="companyCity"
              zip="companyZip"
              readonly="true"
            />
          </div>
          <div v-if="!customer.companyUseContactAddr">
            <AddressInformation
              :customer="customer"
              address1="companyAddress"
              address2="companyAddress2"
              country="companyCountry"
              state="companyState"
              city="companyCity"
              zip="companyZip"
              readonly="false"
            />
          </div>
        </div>
      </div>
      <div class="comp-sec">
        <h4>{{ $t('company.directorInfo') }}</h4>
        <div class="mb-3">
          <label class="required-star" for="C-number">{{ $t('company.numberOfDirectors') }}</label>
          <select
            class="custom-select d-block w-100"
            id="C-number"
            v-model="customer.directorNum"
            required
          >
            <Option value>{{ $t('message.choose') }}...</Option>
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
            <Option value="6">6</Option>
          </select>
        </div>
        <div v-if="customer.directorNum" class="mb-3">
          <div class="card mb-2" v-for="i in parseInt(customer.directorNum)" v-bind:key="i">
            <div class="card-header">{{ $t('company.director') }} {{i}}</div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group row">
                    <label
                      class="col-lg-5 col-form-label required-star"
                    >{{ $t('contact.firstName') }}</label>
                    <div class="col-lg-7">
                      <input
                        type="input"
                        class="form-control"
                        v-model="customer['directorFirstName' + i]"
                        placeholder="first name"
                        value
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group row">
                    <label
                      class="col-lg-5 col-form-label required-star"
                    >{{ $t('contact.lastName') }}</label>
                    <div class="col-lg-7">
                      <input
                        type="text"
                        class="form-control"
                        v-model="customer['directorLastName' + i]"
                        placeholder="last name"
                        value
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="comp-sec">
        <h4>{{ $t('company.stockInfo') }}</h4>
        <div class="row justify-content-between">
          <div class="col-lg-4 mb-3">
            <label class="required-star">{{ $t('company.numberOfSharesAuthorized') }}</label>
            <input
              type="text"
              class="form-control"
              v-model="customer.shareNum"
              placeholder
              value
              required
            />
          </div>
          <div class="col-lg-7 mb-3 desc">
            <h6>{{ $t('company.shares') }}</h6>
            <p>{{ $t('company.sharsContent') }}</p>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-lg-4 mb-3">
            <label class="required-star">{{ $t('company.shareParValue') }}</label>
            <input
              type="text"
              class="form-control"
              v-model="customer.shareValue"
              placeholder
              value
              required
            />
          </div>
          <div class="col-lg-7 mb-3 desc">
            <h6>{{ $t('company.parValue') }}</h6>
            <p>{{ $t('company.parValueContent') }}</p>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-lg-4 mb-3">
            <div class="mb-3">
              <label class="required-star">{{ $t('company.numberOfShareholders') }}</label>
              <select class="custom-select d-block w-100" v-model="customer.shareholder" required>
                <Option value>{{ $t('message.choose') }}...</Option>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
              </select>
            </div>
          </div>
          <div class="col-lg-7 mb-3 desc">
            <h6>{{ $t('company.shareholders') }}</h6>
            <p>{{ $t('company.shareholdersContent') }}</p>
          </div>
        </div>
      </div>
    </template>
    <!--company information LLC-->
    <template v-if="customer.type=='LLC'">
      <div class="comp-sec">
        <div class="row">
          <div class="col-lg-8 mb-3">
            <label class="required-star">LLC {{ $t('company.name') }}</label>
            <input
              type="text"
              class="form-control"
              v-model="customer.companyName"
              placeholder
              value
              required
            />
            <div class="invalid-feedback">Valid company name is required.</div>
          </div>
          <div class="col-lg-4 mb-3">
            <label class="required-star">{{ $t('company.designator') }}</label>
            <select class="custom-select d-block w-100" v-model="customer.designator" required>
              <Option value>{{ $t('message.choose') }}...</Option>
              <Option value="LLC">LLC</Option>
              <Option value="L.L.C.">L.L.C.</Option>
              <Option value="LIMITED LIABILITY COMPANY">LIMITED LIABILITY COMPANY</Option>
            </select>
          </div>
        </div>
        <div class="mb-3">
          <label class="required-star">{{ $t('company.industry') }}</label>
          <select
            class="custom-select d-block w-100"
            id="C-industry"
            v-model="customer.industry"
            required
          >
            <Option value>{{ $t('message.choose') }}...</Option>
            <Option value="Health care & social assistance">{{ $t('company.healthCare') }}</Option>
            <Option value="Wholesale-agent/broker">{{ $t('company.broker') }}</Option>
            <Option value="Construction">{{ $t('company.construction') }}</Option>
            <Option value="Rental & leasing">{{ $t('company.rental') }}</Option>
            <Option value="Transportation & warehousing">{{ $t('company.transportation') }}</Option>
            <Option value="Accommodation & food service">{{ $t('company.accommodation') }}</Option>
            <Option value="Wholesale-other">{{ $t('company.wholesale') }}</Option>
            <Option value="Retail">{{ $t('company.retail') }}</Option>
            <Option value="Real estate">{{ $t('company.estate') }}</Option>
            <Option value="Manufacturing">{{ $t('company.manufacturing') }}</Option>
            <Option value="Finance & insurance">{{ $t('company.finance') }}</Option>
            <Option value="Others">{{ $t('company.other') }}</Option>
          </select>
          <div v-if="customer.industry === 'Others'" class="pt-3">
            <input
              type="text"
              class="form-control"
              v-model="customer.industryOther"
              placeholder="Specified business type / industry"
              value
              required
            />
          </div>
          <div class="invalid-feedback">Business type is required.</div>
        </div>

        <div class="mb-3">
          <label class="required-star">{{ $t('company.purpose') }}</label>
          <textarea
            class="form-control"
            v-model="customer.purpose"
            required
            rows="3"
            placeholder="i.e. Selling bakery goods"
          ></textarea>
        </div>
      </div>
      <div class="comp-sec">
        <h4>{{ $t('company.addressInformation') }}</h4>
        <p class="text-left-Large" v-show="customer.state==='CA'" v-html="$t('company.california')"></p>
        <p class="text-left-Large" v-show="customer.state==='DE'" v-html="$t('company.delaware')"></p>
        <div class="card-deck mb-3">
          <div
            class="card mb-6 shadow-sm"
            v-bind:class="{ highlight: !customer.useAddressRent }"
            @click="customer.useAddressRent = false"
          >
            <div class="card-header" v-bind:class="{highlightBack: !customer.useAddressRent}">
              <h4 class="my-0 font-weight-normal">{{ $t('company.ownAddress') }}</h4>
            </div>
            <div class="card-body">
              <ul class="list-unstyled mt-3 mb-4 text-left">
                <li>1. {{ $t('company.ownAddress1', { state: $t('message.' + customer.state.toLowerCase()) })}}</li>
                <li v-show="customer.state==='CA'">2. {{ $t('company.ownAddress2InCa') }}</li>
                <li v-show="customer.state==='DE'">2. {{ $t('company.ownAddress2InDe') }}</li>
                <li>3. {{ $t('company.ownAddress3') }}</li>
              </ul>
            </div>
          </div>
          <div
            class="card mb-6 shadow-sm"
            v-bind:class="{ highlight: customer.useAddressRent }"
            @click="customer.useAddressRent = true"
            v-show="customer.state=='CA'"
          >
            <div class="card-header" v-bind:class="{highlightBack: customer.useAddressRent}">
              <h4 class="my-0 font-weight-normal">{{ $t('company.virtualAddress') }}</h4>
            </div>
            <div class="card-body">
              <p>{{ $t('company.virtualAddress1') }}</p>
              <address>
                160 E Tasman Dr
                <br />San Jose, CA, 95134
              </address>
              <h1>
                $ 300
                <small class="extra-small">{{ $t('message.perYear') }}</small>
              </h1>
              <small>{{ $t('company.virtualAddress2') }}</small>
            </div>
          </div>
          <div
            class="card mb-6 shadow-sm"
            v-bind:class="{ highlight: customer.useAddressRent }"
            @click="customer.useAddressRent = true"
            v-show="customer.state=='DE'"
          >
            <div class="card-header" v-bind:class="{highlightBack: customer.useAddressRent}">
              <h4 class="my-0 font-weight-normal">{{ $t('company.virtualAddress') }}</h4>
            </div>
            <div class="card-body">
              <p>{{ $t('company.virtualAddress1') }}</p>
              <address>
                300 Delaware Avenue, Suite 210
                <br />Wilmington, DE, 19801
              </address>
              <h1>
                $ 300
                <small class="extra-small">{{ $t('message.perYear') }}</small>
              </h1>
              <small>{{ $t('company.virtualAddress2') }}</small>
            </div>
          </div>
        </div>
        <div v-if="!customer.useAddressRent">
          <p class="text-left-Large">
            <input type="checkbox" v-model="customer.companyUseContactAddr" />
            {{ $t('company.useContactAddress') }}
          </p>
          <div v-if="customer.companyUseContactAddr">
            <AddressInformation
              :customer="companyAddr"
              address1="companyAddress"
              address2="companyAddress2"
              country="companyCountry"
              state="companyState"
              city="companyCity"
              zip="companyZip"
              readonly="true"
            />
          </div>
          <div v-if="!customer.companyUseContactAddr">
            <AddressInformation
              :customer="customer"
              address1="companyAddress"
              address2="companyAddress2"
              country="companyCountry"
              state="companyState"
              city="companyCity"
              zip="companyZip"
              readonly="false"
            />
          </div>
        </div>
      </div>
      <div class="comp-sec">
        <h4>{{ $t('company.memberInfo') }}</h4>
        <div class="mb-3">
          <label class="required-star">{{ $t('company.numberOfMember') }}</label>
          <select class="custom-select d-block w-100" v-model="customer.memberNum" required>
            <Option value>{{ $t('message.choose') }}...</Option>
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
          </select>
        </div>
        <div v-if="customer.memberNum" class="mb-3">
          <div class="card mb-2" v-for="i in parseInt(customer.memberNum)" v-bind:key="i">
            <div class="card-header">{{ $t('company.member') }} {{i}}</div>
            <div class="card-body">
              <div class="row mb-3">
                <legend
                  class="col-form-label col-sm-6 pt-0 required-star"
                >{{ $t('company.individualOrCompany') }}</legend>
                <div class="col-sm-6">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      :id="'memberisIndividual' + i"
                      class="custom-control-input"
                      value="true"
                      :name="'memberisIndividual' + i"
                      v-model="customer['memberisIndividual' + i]"
                      required
                    />
                    <label
                      class="custom-control-label"
                      :for="'memberisIndividual' + i"
                    >{{ $t('company.individual') }}</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      :id="'memberisCompany' + i"
                      class="custom-control-input"
                      value="false"
                      :name="'memberisIndividual' + i"
                      v-model="customer['memberisIndividual' + i]"
                      required
                    />
                    <label
                      class="custom-control-label"
                      :for="'memberisCompany' + i"
                    >{{ $t('company.company') }}</label>
                    <div class="invalid-feedback">Choose member type.</div>
                  </div>
                </div>
              </div>
              <div v-if="customer['memberisIndividual' + i] == 'true'">
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <label class="required-star">{{ $t('contact.firstName') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      value
                      v-model="customer['memberFirstName' + i]"
                      required
                    />
                    <div class="invalid-feedback">Valid first name is required.</div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label class="required-star">{{ $t('contact.lastName') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      value
                      v-model="customer['memberLastName' + i]"
                      required
                    />
                    <div class="invalid-feedback">Valid last name is required.</div>
                  </div>
                </div>
              </div>
              <div v-if="customer['memberisIndividual' + i] == 'false'">
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <label class="required-star">{{ $t('company.companyName') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      value
                      v-model="customer['memberCompanyName' + i]"
                      required
                    />
                    <div class="invalid-feedback">Valid company name is required.</div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label class="required-star">{{ $t('company.representative') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      value
                      v-model="customer['memberCompanyRep' + i]"
                      required
                    />
                    <div class="invalid-feedback">Valid Representative is required.</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <legend
                  class="col-form-label col-sm-6 pt-0 required-star"
                >{{ $t('company.addressOfMember') }}</legend>
                <div class="col-sm-6">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      :id="'memberUseContact' + i"
                      class="custom-control-input"
                      value="memberUseContactAddr"
                      :name="'memberAddrType' + i"
                      v-model="customer['memberAddrType' + i]"
                      required
                    />
                    <label
                      class="custom-control-label"
                      :for="'memberUseContact' + i"
                    >{{ $t('company.useContactAddress') }}</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      :id="'memberUseCompany' + i"
                      class="custom-control-input"
                      value="memberUseCompanyAddr"
                      :name="'memberAddrType' + i"
                      v-model="customer['memberAddrType' + i]"
                      required
                    />
                    <label
                      class="custom-control-label"
                      :for="'memberUseCompany' + i"
                    >{{ $t('company.useCompanyAddress') }}</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      :id="'memberUseOwn' + i"
                      class="custom-control-input"
                      value="memberUseOwnAddr"
                      :name="'memberAddrType' + i"
                      v-model="customer['memberAddrType' + i]"
                      required
                    />
                    <label
                      class="custom-control-label"
                      :for="'memberUseOwn' + i"
                    >{{ $t('company.useOwnAddress') }}</label>
                    <div class="invalid-feedback">Valid Address is required.</div>
                  </div>
                </div>
              </div>
              <div v-show="customer['memberAddrType' + i] ==='memberUseContactAddr'">
                <AddressInformation
                  :customer="customer"
                  address1="contactAddress"
                  address2="contactAddress2"
                  country="contactCountry"
                  state="contactState"
                  city="contactCity"
                  zip="contactZip"
                  readonly="true"
                />
              </div>
              <div v-show="customer['memberAddrType' + i] ==='memberUseCompanyAddr'">
                <AddressInformation
                  :customer="companyAddr"
                  address1="companyAddress"
                  address2="companyAddress2"
                  country="companyCountry"
                  state="companyState"
                  city="companyCity"
                  zip="companyZip"
                  readonly="true"
                />
              </div>
              <div v-if="customer['memberAddrType' + i]==='memberUseOwnAddr'">
                <AddressInformation
                  :customer="customer"
                  :address1="'memberAddress' + i"
                  :address2="'memberAddress2' + i"
                  :country="'memberCountry' + i"
                  :state="'memberState' + i"
                  :city="'memberCity' + i"
                  :zip="'memberZip' + i"
                  readonly="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="comp-sec">
        <h4>{{ $t('company.memberManager') }}</h4>
        <p
          class="text-left-Large required-star"
          v-html="$t('company.memberManagerContent', { state: $t('message.' + customer.state.toLowerCase()) })"
        ></p>
        <div class="mx-1">
          <div class="custom-control custom-radio" id="pop1">
            <input
              type="radio"
              id="member1"
              class="custom-control-input"
              value="The LLC will be managed by the LLC member(s)"
              name="LLCmembers"
              checked
              v-model="customer.memberOption"
            />
            <label class="custom-control-label" for="member1">{{ $t('company.memberManager1') }}</label>
          </div>
          <div class="custom-control custom-radio" id="pop2">
            <input
              type="radio"
              id="member2"
              class="custom-control-input"
              value="The LLC will be managed by One Manager"
              name="LLCmembers"
              v-model="customer.memberOption"
            />
            <label class="custom-control-label" for="member2">{{ $t('company.memberManager2') }}</label>
          </div>
          <div class="custom-control custom-radio" id="pop3">
            <input
              type="radio"
              id="member3"
              class="custom-control-input"
              value="The LLC will be managed by More than One Manager"
              name="LLCmembers"
              v-model="customer.memberOption"
            />
            <label class="custom-control-label" for="member3">{{ $t('company.memberManager3') }}</label>
          </div>
          <BPopover target="pop1" triggers="hover">
            <div class="p-2">
              <h6>{{ $t('Member-Managed LLC') }}</h6>
              <p>
                {{ $t('The vast majority of LLCs are most correctly classified as Member-Managed, and approximately 99.9% of all LLCs filed by CorpLaw are Member-managed LLCs.') }}
                <br />
                {{ $t('As the name might indicate, for a Member-Managed LLC, all of the LLC members (owners) are responsible for the day to day management of the LLC, and this is regardless of whether the LLC has one or multiple members.') }}
              </p>
            </div>
          </BPopover>
          <BPopover target="pop2" triggers="hover">
            <div class="p-2">
              <h6>{{ $t('Manager-Managed LLC') }}</h6>
              <p>{{ $t('This is a much less common management structure. For this type of arrangement, one or more (or all) of the LLC members (owners) will take a passive role, and not be involved in the day to day management of the LLC.') }}</p>
              <p>{{ $t('This may take on a few different forms, as follows:') }}</p>
              <p>{{ $t('A.) The LLC management will be vested in the members of the LLC - One or more of the LLC members will be actively involved in the day to day management of the LLC, while one or more of the LLC members will take a passive role and not participate in the day to day management of the LLC.') }}</p>
              <p>{{ $t('B.) The LLC management will be vested in nonmembers of the LLC - One or more managers that are not members/owners of the LLC may be appointed/hired to be responsible for the day to day management of the LLC.') }}</p>
              <p>{{ $t('C.) Both A and B - The management of the LLC may be vested in some combination of both members and nonmembers.') }}</p>
            </div>
          </BPopover>
          <BPopover target="pop3" triggers="hover">
            <div class="px-2">
              <h6>{{ $t('Manager-Managed LLC') }}</h6>
              <p>{{ $t('This is a much less common management structure. For this type of arrangement, one or more (or all) of the LLC members (owners) will take a passive role, and not be involved in the day to day management of the LLC.') }}
              </p>
              <p>{{ $t('This may take on a few different forms, as follows:') }}</p>
              <p>{{ $t('A.) The LLC management will be vested in the members of the LLC - One or more of the LLC members will be actively involved in the day to day management of the LLC, while one or more of the LLC members will take a passive role and not participate in the day to day management of the LLC.') }}</p>
              <p>{{ $t('B.) The LLC management will be vested in nonmembers of the LLC - One or more managers that are not members/owners of the LLC may be appointed/hired to be responsible for the day to day management of the LLC.') }}</p>
              <p>{{ $t('B.) The LLC management will be vested in nonmembers of the LLC - One or more managers that are not members/owners of the LLC may be appointed/hired to be responsible for the day to day management of the LLC.') }}</p>
              <p>{{ $t('C.) Both A and B - The management of the LLC may be vested in some combination of both members and nonmembers.') }}</p>
            </div>
          </BPopover>
        </div>
      </div>
    </template>
    <div class="comp-sec" v-if="customer.plan == 'PREMIUM'">
      <h4>{{ $t('company.taxInfo') }}</h4>
      <div class="mb-3">
        <label>{{ $t('company.fictitiousName') }}</label>
        <span class="text-muted">{{ $t('message.optional') }}</span>
        <input type="text" class="form-control" v-model="customer.fictitious" placeholder />
      </div>
      <div class="mb-3">
        <label class="required-star">{{ $t('company.businessLocated') }}</label>
        <div class="row">
          <div class="col-lg-6 mb-3">
            <input
              type="text"
              class="form-control"
              v-model="customer.businessCounty"
              placeholder="county"
              required
            />
            <div class="invalid-feedback">County required.</div>
          </div>
          <div class="col-lg-6 mb-3">
            <input
              type="text"
              class="form-control"
              v-model="customer.businessState"
              placeholder="state"
              required
            />
            <div class="invalid-feedback">State required.</div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="required-star">{{ $t('company.nameOfResponsibleParty') }}</label>
        <div class="row">
          <div class="col-lg-6 mb-3">
            <input
              type="text"
              class="form-control"
              v-model="customer.responsibleFirstName"
              placeholder="first name"
              required
            />
            <div class="invalid-feedback">First name required.</div>
          </div>
          <div class="col-lg-6 mb-3">
            <input
              type="text"
              class="form-control"
              v-model="customer.responsibleLastName"
              placeholder="last name"
              required
            />
            <div class="invalid-feedback">Last name required.</div>
          </div>
        </div>
      </div>
      <div class="custom-control custom-checkbox mb-2">
        <input
          type="checkbox"
          class="custom-control-input"
          v-model="customer.resposibleNoSSN"
          id="resposibleNoSSN"
        />
        <label class="custom-control-label" for="resposibleNoSSN">{{ $t('company.noSSN') }}</label>
      </div>
      <div class="mb-3" v-if="customer.resposibleNoSSN">
        <input type="text" class="form-control" placeholder="SSN/ITIN" readonly />
      </div>
      <div class="mb-3" v-if="!customer.resposibleNoSSN">
        <input
          type="text"
          class="form-control"
          v-model="customer.resposibleSSN"
          placeholder="SSN/ITIN"
          pattern="[0-9]{9}"
          required
        />
        <div class="invalid-feedback">Please enter valid SSN/ITIN.</div>
      </div>
      <div class="row">
        <div class="col-lg-6 mb-3">
          <label class="required-star">{{ $t('company.startedDate') }}</label>
          <date-picker v-model="customer.startDate" />
          <div class="invalid-feedback">Valid date required.</div>
        </div>
        <div class="col-lg-6 mb-3">
          <label class="required-star">{{ $t('company.closedMonth') }}</label>
          <select class="custom-select d-block w-100" v-model="customer.closingMonth" required>
            <Option value>Month</Option>
            <Option value="January">January</Option>
            <Option value="February">February</Option>
            <Option value="March">March</Option>
            <Option value="April">April</Option>
            <Option value="May">May</Option>
            <Option value="June">June</Option>
            <Option value="July">July</Option>
            <Option value="August">August</Option>
            <Option value="September">September</Option>
            <Option value="October">October</Option>
            <Option value="November">November</Option>
            <Option value="December">December</Option>
          </select>
          <div class="invalid-feedback">Please choose closing month.</div>
        </div>
      </div>
    </div>
    <hr class="mb-4" />
  </div>
</template>

<script>
import { BPopover } from 'bootstrap-vue'
import AddressInformation from './AddressInformation'
import DatePicker from './date-picker'

export default {
  name: 'CompanyInformation',
  components: { AddressInformation, BPopover, DatePicker },
  props: ['customer', 'companyAddr'],
  created() {
    if (!('fictitious' in this.customer)) {
      this.customer.fictitious = ''
    }
  },
}
</script>

<style scoped>
.required-star::after {
  content: ' *';
  color: red;
}

.comp-sec h4 {
  color: darkblue;
  margin: 20px 0;
}

.comp-sec {
  margin: 30px 0;
}

.text-left-Large {
  text-align: left;
  font-weight: 700;
  padding: 20px 0;
}

.highlight {
  border: solid mediumslateblue;
}

.highlightBack {
  background-color: mediumslateblue;
}

.extra-small {
  font-size: 1rem;
}

.date-picker-container {
  position: relative;
}
</style>

