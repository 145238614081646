<template>
  <div class="container">
    <div class="row">
      <div
        class="comp-sec optional-price-card"
        @click="$emit('option1Click')"
        v-if="!shouldHideAnnualReport"
      >
        <div class="price-card-col1">
          <label class="checkbox-container">
            <input
              type="checkbox"
              id="service1"
              name="service1"
              :checked="customer.annualReport"
              value="service1"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="price-card-col2">
          <h4>{{ $t('optionalService.initial') }}</h4>
          <span>{{ $t('optionalService.initialHint') }}</span>
          <div class="price-span">
            <div>{{ $t('optionalService.serviceFee') }}: $100</div>
            <div>
              {{ $t('optionalService.governmentFee') }}:
              ${{customer.type === 'LLC' ? 20 : 25}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="comp-sec optional-price-card" @click="$emit('option3Click')">
        <div class="price-card-col1">
          <label class="checkbox-container">
            <input
              type="checkbox"
              id="service3"
              name="service3"
              :checked="customer.trademarkRegister"
              value="service3"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="price-card-col2">
          <h4>{{ $t('optionalService.trademark') }}</h4>
          <span>{{ $t('optionalService.trademarkHint') }}</span>
          <div class="price-span">
            <div>{{ $t('optionalService.serviceFee') }}: $500</div>
            <div>{{ $t('optionalService.governmentFee') }}：$275/{{ $t('optionalService.category') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['customer'],
  name: 'optionalService',
  computed: {
    shouldHideAnnualReport() {
      return this.customer.state === 'DE'
    },
  },
}
</script>

<style scoped>
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: solid 0.5px #dddddd;
  border-radius: 5px;
  pointer-events: none;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.price-card-col1 {
  float: left;
  width: 5%;
  position: relative;
  top: 40%;
}

.price-card-col2 {
  float: right;
  width: 92%;
}

.optional-price-card {
  border: solid mediumslateblue;
  padding: 1rem;
  border-radius: 1.5rem;
  margin: 15px 0;
  min-width: 100%;
  cursor: pointer;
}

.optional-price-card.disabled {
  border: none;
  cursor: not-allowed;
}

.price-span {
  padding-top: 1rem;
}

.required-star::after {
  content: ' *';
  color: red;
}

.optional-price-card .comp-sec h4 {
  color: darkblue;
  margin: 10px 0;
}

.text-left-Large {
  text-align: left;
  font-weight: 700;
  padding: 20px 0;
}

.highlight {
  border: solid mediumslateblue;
}

.highlightBack {
  background-color: mediumslateblue;
}

.extra-small {
  font-size: 1rem;
}
</style>