<template>
  <div class="container">
    <form ref="agentinfo">
      <p class="text-left-Large">
        {{ $t('agent.content1') }}
        <br />
        <br />
        {{ $t('agent.content2') }}
        <br />
        <br />
        {{ $t('agent.content3') }}
      </p>
      <p class="text-left-Large" style="color: darkblue">{{ $t('agent.content4') }}</p>
      <hr class="mb-4" />
    </form>
  </div>
</template>

<script>
export default {
  name: 'AgentInformation',
  // props: ['customer',],
}
</script>

<style scoped>
</style>
