<template>
  <div class="card-header">
    <label id="label">{{ $t('payment.cardType') }}</label>
    <div id="card-element" />
    <div id="card-errors" role="alert">{{message}}</div>
  </div>
</template>
<script>
// eslint-disable-next-line
let stripe = Stripe(process.env.VUE_APP_STRIPE)
let elements = stripe.elements()

// Custom styling can be passed to options when creating an Element.
// (Note that this demo uses a wider set of styles than the guide below.)
let style = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
}

// Create an instance of the card Element.
var card = elements.create('card', { style: style })

export default {
  props: ['message'],
  mounted: function() {
    card.mount('#card-element')
    card.addEventListener('change', event => {
      if (event.error) {
        this.message = event.error.message
      } else {
        this.message = ''
      }
    })
  },
  methods: {
    alert: function(event) {
      alert()
      this.message = event.error.message
    },
    charge: function() {
      let result = stripe.createSource(card)
      if (result.error) {
        this.message = result.error.message
      }
      return result
    },
  },
}
</script>
<style scoped>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#label {
  text-align: left;
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}

#card-errors {
  height: 20px;
  padding: 4px 0;
  color: #fa755a;
}
.card-header {
  border-bottom: none;
}
</style>
