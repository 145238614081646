<template>
  <div class="container">
    <div class="form-row">
      <div class="col-lg-6 mb-3">
        <label class="required-star">{{ $t('contact.firstName') }}</label>
        <input
          type="text"
          class="form-control"
          v-model="customer.contactFirstName"
          placeholder="First name"
          value
          required
        />
        <div class="invalid-feedback">Valid first name is required.</div>
      </div>
      <div class="col-lg-6 mb-3">
        <label class="required-star">{{ $t('contact.lastName') }}</label>
        <input
          type="text"
          class="form-control"
          v-model="customer.contactLastName"
          placeholder="Last name"
          value
          required
        />
        <div class="invalid-feedback">Valid last name is required.</div>
      </div>
    </div>
    <div class="mb-3">
      <label class="required-star">{{ $t('contact.email') }}</label>
      <input
        type="email"
        class="form-control"
        v-model="customer.contactEmail"
        placeholder="you@example.com"
        required
      />
      <div class="invalid-feedback">Please enter a valid email address.</div>
    </div>
    <div class="mb-3">
      <label class="required-star">{{ $t('contact.phoneNumber') }}</label>
      <input
        type="tel"
        class="form-control"
        v-model="customer.contactPhone"
        :placeholder="$t('contact.phoneNumberPlaceholder')"
        required
      />
      <div class="invalid-feedback">Please enter a valid phone number.</div>
    </div>
    <AddressInformation
      :customer="customer"
      address1="contactAddress"
      address2="contactAddress2"
      country="contactCountry"
      state="contactState"
      city="contactCity"
      zip="contactZip"
      readonly="false"
    />
    <hr class="mb-4" />
  </div>
</template>

<script>
import AddressInformation from './AddressInformation'
export default {
  name: 'ContactInformation',
  components: { AddressInformation },
  props: ['customer'],
}
</script>

<style scoped>
.required-star::after {
  content: ' *';
  color: red;
}
</style>