<template>
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-lg-4 order-lg-2 mb-4">
        <OrderSummary v-bind:customer="customer"></OrderSummary>
      </div>
      <div class="col-lg-8 order-lg-1">
        <div
          style="color: #d01414; font-size: 0.8em"
          v-if="$i18n.locale === 'cn'"
        >请使用英文填写此问卷。问卷信息将被用于公司成立申请，请仔细填写。</div>
        <div v-show="steps">
          <!--contact information-->
          <div class="mb-3 order-lable">
            <h4 class="left card-title">1. {{$t('contact.title')}}</h4>
            <h4 class="right">{{ $t('pagination.step', { current: 1, total: totalStepCount }) }}</h4>
          </div>
          <form ref="contactinfo">
            <div class="stepsContent" v-if="contactInfor">
              <ContactInformation ref="contactInformation" v-bind:customer="customer" />
              <div class="row justify-content-around" v-show="updateBtn == false">
                <div class="col-4 mb-3">
                  <a class="back" @click="backtoPrice">
                    <font-awesome-icon icon="caret-left" />
                    <span class="button-text">{{ $t('pagination.back') }}</span>
                  </a>
                </div>
                <div class="col-4 mb-3">
                  <a class="next" @click="companyInf">
                    <span class="button-text">{{ $t('pagination.next') }}</span>
                    <font-awesome-icon icon="caret-right" />
                  </a>
                </div>
              </div>
              <div class="row justify-content-around" v-show="updateBtn == true">
                <div class="col-lg-4 mb-3">
                  <a class="back" @click="reviewInf">
                    <span class="button-update">{{ $t('pagination.update') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </form>

          <!--company information-->
          <div class="mb-3 order-lable">
            <h4 class="left card-title">2. {{ $t('company.title') }}</h4>
            <h4 class="right">{{ $t('pagination.step', { current: 2, total: totalStepCount }) }}</h4>
          </div>
          <form ref="companyinfo">
            <div class="stepsContent" v-if="compInfor">
              <CompanyInformation
                ref="companyInformation"
                v-bind:customer="customer"
                v-bind:companyAddr="companyAddr"
              />
              <div class="row justify-content-around" v-show="updateBtn == false">
                <div class="col-4 mb-3">
                  <a class="back" @click="contactInf">
                    <font-awesome-icon icon="caret-left" />
                    <span class="button-text">{{ $t('pagination.back') }}</span>
                  </a>
                </div>
                <div class="col-4 mb-3">
                  <a class="next" @click="agentInf">
                    <span class="button-text">{{ $t('pagination.next') }}</span>
                    <font-awesome-icon icon="caret-right" />
                  </a>
                </div>
              </div>
              <div class="row justify-content-around" v-show="updateBtn == true">
                <div class="col-lg-4 mb-3">
                  <a class="back" @click="reviewInf">
                    <span class="button-update">{{ $t('pagination.update') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </form>

          <!--agent information-->
          <div class="mb-3 order-lable">
            <h4 class="left card-title">3. {{ $t('agent.title') }}</h4>
            <h4 class="right">{{ $t('pagination.step', { current: 3, total: totalStepCount }) }}</h4>
          </div>
          <div class="stepsContent" v-if="agentInfor">
            <!--                        <AgentInformation v-bind:customer="customer" v-on:next="toReview" v-on:back="toConpamy"/>-->
            <AgentInformation v-bind:customer="customer" />
            <div class="row justify-content-around">
              <div class="col-4 mb-3">
                <a class="back" @click="companyInf">
                  <font-awesome-icon icon="caret-left" />
                  <span class="button-text">{{ $t('pagination.back') }}</span>
                </a>
              </div>
              <div class="col-4 mb-3">
                <a class="next" @click="optionalInf">
                  <span class="button-text">{{ $t('pagination.next') }}</span>
                  <font-awesome-icon icon="caret-right" />
                </a>
              </div>
            </div>
          </div>

          <!--optional service-->
          <div class="mb-3 order-lable">
            <h4 class="left card-title">4. {{ $t('optionalService.title') }}</h4>
            <h4 class="right">{{ $t('pagination.step', { current: 4, total: totalStepCount }) }}</h4>
          </div>
          <div class="stepsContent" v-if="optionalInfor">
            <OptionalService
              v-bind:customer="customer"
              @option1Click="updateService(1)"
              @option2Click="updateService(2)"
              @option3Click="updateService(3)"
            />
            <div class="row justify-content-around">
              <div class="col-4 mb-3">
                <a class="back" @click="agentInf">
                  <font-awesome-icon icon="caret-left" />
                  <span class="button-text">{{ $t('pagination.back') }}</span>
                </a>
              </div>
              <div class="col-4 mb-3">
                <a class="next" @click="reviewInf">
                  <span class="button-text">{{ $t('pagination.review') }}</span>
                  <font-awesome-icon icon="caret-right" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <!--order review-->
        <div v-if="review">
          <div class="mb-3 order-lable">
            <h4 class="left card-title">{{ $t('review.title') }}</h4>
          </div>
          <Summary v-bind:customer="customer" editBtn="true" v-on:edit="onEdit" />
          <div class="row justify-content-around">
            <div class="col-4 mb-3">
              <a class="back" @click="optionalInf">
                <font-awesome-icon icon="caret-left" />
                <span class="button-text">{{ $t('pagination.back') }}</span>
              </a>
            </div>
            <div class="col-4 mb-3">
              <a class="next" @click="payInf">
                <span class="button-text">{{ $t('pagination.payment') }}</span>
                <font-awesome-icon icon="caret-right" />
              </a>
            </div>
          </div>
        </div>

        <!--payment-->
        <div v-show="payment">
          <div class="mb-3 order-lable">
            <h4 class="left card-title">{{ $t('payment.title') }}</h4>
          </div>
          <form ref="paymentinfo">
            <h4 class="mb-3">{{ $t('payment.billingAddress') }}</h4>
            <div class="comp-sec">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-model="customer.billingUseContactAddr"
                  id="billingUseContactAddr"
                />
                <label
                  class="custom-control-label"
                  for="billingUseContactAddr"
                >{{ $t('company.useContactAddress') }}</label>
                <br />
              </div>
              <div v-show="customer.billingUseContactAddr">
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <label class="required-star">{{ $t('payment.firstName') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      v-bind:value="customer.contactFirstName"
                      readonly
                    />
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label class="required-star">{{ $t('payment.lastName') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      v-bind:value="customer.contactLastName"
                      readonly
                    />
                  </div>
                </div>
                <AddressInformation
                  :customer="customer"
                  address1="contactAddress"
                  address2="contactAddress2"
                  country="contactCountry"
                  state="contactState"
                  city="contactCity"
                  zip="contactZip"
                  readonly="true"
                />
              </div>
              <div v-if="!customer.billingUseContactAddr">
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <label class="required-star">{{ $t('payment.firstName') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      value
                      v-model="customer.billingFirstName"
                      required
                    />
                    <div class="invalid-feedback">Valid first name is required.</div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label class="required-star">{{ $t('payment.lastName') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      value
                      v-model="customer.billingLastName"
                      required
                    />
                    <div class="invalid-feedback">Valid last name is required.</div>
                  </div>
                </div>
                <AddressInformation
                  :customer="customer"
                  address1="billingAddress"
                  address2="billingAddress2"
                  country="billingCountry"
                  state="billingState"
                  city="billingCity"
                  zip="billingZip"
                  readonly="false"
                />
              </div>
            </div>
            <hr class="mb-4" />
            <h4 class="mb-3">{{ $t('payment.info') }}</h4>
            <CreditCard ref="cc" v-bind:message="message" />
            <div class="mt-3">
              <label>Coupon Code</label>
              <input
                type="text"
                class="form-control mb-2"
                v-model="customer.coupon"
              />
              <input type="checkbox" class="mr-2" id="declaimer" required />
              <label class="required-star" for="declaimer" v-html="$t('payment.agreePolicy')"></label>
              <br />
            </div>
            <hr class="mb-4" />
          </form>
          <div v-show="payment" class="row justify-content-around">
            <div class="col-4 mb-3">
              <a class="back" @click="reviewInf">
                <font-awesome-icon icon="caret-left" />
                <span class="button-text">{{ $t('pagination.back') }}</span>
              </a>
            </div>
            <div class="col-4 mb-3">
              <a class="next">
                <button
                  class="btn btn-primary"
                  v-on:click="complete"
                  :disabled="validating"
                >{{ $t('payment.pay') }}</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import CreditCard from '@/components/CheckOut/CreditCard'
import Summary from '@/components/CheckOut/Summary'
import ContactInformation from '@/components/CheckOut/ContactInformation'
import CompanyInformation from '@/components/CheckOut/CompanyInformation'
import AgentInformation from '@/components/CheckOut/AgentInformation'
import OptionalService from '@/components/CheckOut/OptionalService'
import OrderSummary from '@/components/CheckOut/OrderSummary'
import AddressInformation from '@/components/CheckOut/AddressInformation'
import jq from 'jquery'

// eslint-disable-next-line
let elements = Stripe('pk_test_TYooMQauvdEDq54NiTphI7jx').elements({
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Source+Code+Pro',
    },
  ],
  locale: 'auto',
})

export default {
  name: 'CheckOut',
  components: {
    AgentInformation,
    ContactInformation,
    CompanyInformation,
    AddressInformation,
    CreditCard,
    Summary,
    OrderSummary,
    OptionalService,
  },
  data() {
    return {
      message: '',
      validating: false,
      customer: {
        shareNum: 10000000,
        shareValue: 0.001,
        useAddressRent: false,
        memberOption: 'The LLC will be managed by the LLC member(s)',
        closingMonth: '',
        annualReport: false,
        bankAccountSetup: false,
        trademarkRegister: false,
        coupon: '',
      },
      steps: true,
      contactInfor: true,
      compInfor: false,
      agentInfor: false,
      optionalInfor: false,
      review: false,
      payment: false,
      noverify: false,
      updateBtn: false,
      waiting: false,
    }
  },
  created() {
    this.noverify = this.$route.query.noverify
    if (this.$route.query.state !== undefined) {
      localStorage.state = this.$route.query.state
    }
    if (this.$route.query.type !== undefined) {
      localStorage.type = this.$route.query.type
    }
    if (this.$route.query.plan !== undefined) {
      localStorage.plan = this.$route.query.plan
    }

    if (localStorage.customer) {
      try {
        this.customer = {
          ...this.customer,
          ...JSON.parse(localStorage.customer),
        }
      } catch (err) {
        localStorage.removeItem('customer')
        this.$router.push({
          path: '/pricing',
        })
      }
    }

    if (localStorage.state) {
      this.customer.state = localStorage.state
    }
    if (localStorage.type) {
      this.customer.type = localStorage.type
    }
    if (localStorage.plan) {
      this.customer.plan = localStorage.plan
    }
    if (!this.customer.plan || !this.customer.type || !this.customer.state) {
      this.$router.push({
        path: '/pricing',
      })
    } else {
      this.waiting = false
      this.contactInf()
    }

    this.$root.$i18n.locale = this.$route.query.locale || 'en'
  },
  computed: {
    companyAddr: function() {
      if (
        this.customer.useAddressRent === true &&
        this.customer.state === 'CA'
      ) {
        return {
          companyAddress: '160 E Tasman Dr',
          companyCountry: 'United States',
          companyState: 'CA',
          companyCity: 'San Jose',
          companyZip: '95134',
        }
      }
      if (
        this.customer.useAddressRent === true &&
        this.customer.state === 'DE'
      ) {
        return {
          companyAddress: '300 Delaware Avenue, Suite 210',
          companyCountry: 'United States',
          companyState: 'DE',
          companyCity: 'Wilmington',
          companyZip: '19801',
        }
      }
      if (
        this.customer.useAddressRent === false &&
        this.customer.companyUseContactAddr === true
      ) {
        return {
          companyAddress: this.customer.contactAddress,
          companyAddress2: this.customer.contactAddress2,
          companyCountry: this.customer.contactCountry,
          companyState: this.customer.contactState,
          companyCity: this.customer.contactCity,
          companyZip: this.customer.contactZip,
        }
      }
      return {
        companyAddress: this.customer.companyAddress,
        companyAddress2: this.customer.companyAddress2,
        companyCountry: this.customer.companyCountry,
        companyState: this.customer.companyState,
        companyCity: this.customer.companyCity,
        companyZip: this.customer.companyZip,
      }
    },
    totalStepCount() {
      return 4
    },
  },
  methods: {
    updateService(value) {
      switch (value) {
        case 1:
          this.customer.annualReport = !this.customer.annualReport
          break
        case 2:
          this.customer.bankAccountSetup = !this.customer.bankAccountSetup
          break
        case 3:
          this.customer.trademarkRegister = !this.customer.trademarkRegister
          break
      }
    },
    contactInf() {
      this.contactInfor = true
      this.compInfor = false
      this.agentInfor = false
      this.optionalInfor = false
      this.review = false
      this.payment = false
      this.steps = true
      this.edit = false
      localStorage.customer = JSON.stringify(this.customer)
      window.scrollTo(0, 0)
    },
    backtoPrice() {
      history.back()
    },
    companyInf() {
      this.$refs.contactinfo.classList.add('was-validated')
      if (!this.noverify && !this.$refs.contactinfo.checkValidity()) {
        window.scrollTo(0, jq('.was-validated :invalid').offset().top)
        return
      }
      this.steps = true
      this.contactInfor = false
      this.compInfor = true
      this.agentInfor = false
      this.review = false
      this.payment = false
      this.edit = false
      localStorage.customer = JSON.stringify(this.customer)
      window.scrollTo(0, 0)
    },
    agentInf() {
      this.$refs.companyinfo.classList.add('was-validated')
      if (!this.noverify && !this.$refs.companyinfo.checkValidity()) {
        window.scrollTo(0, jq('.was-validated :invalid').offset().top)
        return
      }
      this.steps = true
      this.contactInfor = false
      this.compInfor = false
      this.agentInfor = true
      this.optionalInfor = false
      this.review = false
      this.payment = false
      this.edit = false
      localStorage.customer = JSON.stringify(this.customer)
      window.scrollTo(0, 0)
    },
    optionalInf() {
      this.steps = true
      this.contactInfor = false
      this.compInfor = false
      this.agentInfor = false
      this.optionalInfor = true
      this.review = false
      this.payment = false
      this.edit = false
      localStorage.customer = JSON.stringify(this.customer)
      window.scrollTo(0, 0)
    },
    reviewInf() {
      this.customer.companyAddress = this.companyAddr.companyAddress
      this.customer.companyAddress2 = this.companyAddr.companyAddress2
      this.customer.companyCountry = this.companyAddr.companyCountry
      this.customer.companyState = this.companyAddr.companyState
      this.customer.companyCity = this.companyAddr.companyCity
      this.customer.companyZip = this.companyAddr.companyZip
      for (let i = 1; i <= parseInt(this.customer.memberNum); i++) {
        if (this.customer['memberAddrType' + i] === 'memberUseContactAddr') {
          this.customer['memberAddress' + i] = this.customer.contactAddress
          this.customer['memberAddress2' + i] = this.customer.contactAddress2
          this.customer['memberCountry' + i] = this.customer.contactCountry
          this.customer['memberState' + i] = this.customer.contactState
          this.customer['memberCity' + i] = this.customer.contactCity
          this.customer['memberZip' + i] = this.customer.contactZip
        }
        if (this.customer['memberAddrType' + i] === 'memberUseCompanyAddr') {
          this.customer['memberAddress' + i] = this.customer.companyAddress
          this.customer['memberAddress2' + i] = this.customer.companyAddress2
          this.customer['memberCountry' + i] = this.customer.companyCountry
          this.customer['memberState' + i] = this.customer.companyState
          this.customer['memberCity' + i] = this.customer.companyCity
          this.customer['memberZip' + i] = this.customer.companyZip
        }
      }
      if (this.customer.resposibleNoSSN) {
        this.customer.resposibleSSN = 'Foreign'
      }
      this.steps = false
      this.review = true
      this.payment = false
      localStorage.customer = JSON.stringify(this.customer)
      window.scrollTo(0, 0)
    },
    payInf() {
      this.steps = false
      this.review = false
      this.payment = true
      localStorage.customer = JSON.stringify(this.customer)
      window.scrollTo(0, 0)
    },

    onEdit(info) {
      if (info === 'editContactInf') {
        this.contactInf()
        this.backEdit()
      } else if (info === 'editOptionalService') {
        this.optionalInf()
        this.backEdit()
      } else {
        this.companyInf()
        this.backEdit()
      }
    },

    backEdit() {
      this.updateBtn = true
      localStorage.customer = JSON.stringify(this.customer)
      window.scrollTo(0, 0)
    },

    complete() {
      this.$refs.paymentinfo.classList.add('was-validated')
      if (!this.noverify && !this.$refs.paymentinfo.checkValidity()) {
        window.scrollTo(0, jq('.was-validated :invalid').offset().top)
        return
      }
      if (this.customer.billingUseContactAddr === true) {
        this.customer.billingAddress = this.customer.contactAddress
        this.customer.billingAddress2 = this.customer.contactAddress2
        this.customer.billingCountry = this.customer.contactCountry
        this.customer.billingState = this.customer.contactState
        this.customer.billingCity = this.customer.contactCity
        this.customer.casestartDate = Date.now()
        this.customer.billingZip = this.customer.contactZip
      }
      this.validating = true
      this.$refs.cc.charge().then(result => {
        const user_lang = this.$i18n.locale === 'cn' ? 1 : 0

        if (result.source) {
          this.$apollo
            .mutate({
              mutation: gql`
                mutation(
                  $package: FormationPackage!
                  $state: FormationState!
                  $type: FormationCompanyType!
                  $useProfessionalAddress: Boolean!
                  $annualReport: Boolean!
                  $bankAccountSetup: Boolean!
                  $trademarkRegister: Boolean!
                  $useRegisteredAgent: Boolean!
                  $billingSourceID: String!
                  $caseInfo: JSONString!
                  $userLanguage: Int
                  $coupon: String
                ) {
                  createCase(
                    package: {
                      formPackage: $package
                      formState: $state
                      formCompanyType: $type
                      useProfessionalAddress: $useProfessionalAddress
                      annualReport: $annualReport
                      bankAccountSetup: $bankAccountSetup
                      trademarkRegister: $trademarkRegister
                      useRegisteredAgent: $useRegisteredAgent
                    }
                    billingSource: $billingSourceID
                    caseInfo: $caseInfo
                    userLanguage: $userLanguage
                    couponCode: $coupon
                  ) {
                    ok
                    message
                    order {
                      orderId
                    }
                  }
                }
              `,
              variables: {
                package: this.customer.plan,
                state: this.customer.state,
                type: this.customer.type,
                useProfessionalAddress: Boolean(this.useAddressRent),
                annualReport: this.customer.annualReport,
                bankAccountSetup: this.customer.bankAccountSetup,
                trademarkRegister: this.customer.trademarkRegister,
                useRegisteredAgent: true,
                billingSourceID: result.source.id,
                caseInfo: JSON.stringify(this.customer),
                userLanguage: user_lang,
                coupon: this.customer.coupon,
              },
            })
            .then(res => {
              if (!res.data.createCase.ok) {
                this.validating = false
                this.message = res.data.createCase.message
              } else {
                localStorage.removeItem('customer')
                const param = {
                  orderId: res.data.createCase.order.orderId,
                }
                this.$router.push({
                  path: '/success',
                  query: param,
                })
                window.scrollTo(0, 0)
              }
            })
            .catch(() => {
              this.validating = false
              this.message = 'Error Occurred, try again later'
            })
        } else {
          this.validating = false
        }
      })
    },
  },
  mounted() {
    const state = localStorage.getItem('state')

    if (state === 'DE') {
      this.customer.annualReport = false
      localStorage.setItem('customer', JSON.stringify(this.customer))
    }
  },
}
</script>

<style scoped>
.required-star::after {
  content: ' *';
  color: red;
}

h4 {
  margin-bottom: 0;
}

h4.card-title {
  text-transform: uppercase;
}

.list-group {
  margin: 20px;
}

.order-lable {
  background-color: lightgrey;
  padding: 10px;
  margin: 15px 0;
  overflow: hidden;
}

.left {
  float: left;
  margin: 0;
}

.right {
  float: right;
  font-size: medium;
  padding-top: 5px;
}

.back,
.next {
  cursor: pointer;
}

.button-text {
  margin: 0 20px;
  text-decoration: underline;
  font-size: large;
}

.button-update {
  margin: 0 20px;
  padding: 10px 40px;
  border-radius: 10px;
  font-size: large;
  background-color: mediumslateblue;
  color: white;
}

.comp-sec h4 {
  color: darkblue;
  margin: 20px 0;
}

.comp-sec {
  margin: 30px 0;
}

.policy {
  margin: 20px 0;
  text-align: left;
}
</style>
