<template>
  <div v-if="billing">
    <h4 class="justify-content-between my-3 text-center">{{ $t('order.title') }}</h4>
    <ul class="list-group mb-3">
      <li
        class="list-group-item d-flex justify-content-between lh-condensed"
        v-for="item in (billing.itemList)"
        :key="item.name"
      >
        <span>{{ $t(item.name) }}</span>
        <span class="my-0 text-nowrap">$ {{item.totalFee}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between">
        <h5>{{ $t('order.total') }} (USD)</h5>
        <h5>$ {{billing.totalFee}}</h5>
      </li>
    </ul>
  </div>
</template>

<script>
import gql from 'graphql-tag'
export default {
  name: 'OrderSummary',
  props: ['customer'],
  apollo: {
    billing() {
      return {
        // gql query
        query: gql`
          query pricing(
            $package: FormationPackage!
            $state: FormationState!
            $type: FormationCompanyType!
            $useProfessionalAddress: Boolean!
            $annualReport: Boolean!
            $bankAccountSetup: Boolean!
            $trademarkRegister: Boolean!
            $useRegisteredAgent: Boolean!
          ) {
            billing: cart(
              package: {
                formPackage: $package
                formState: $state
                formCompanyType: $type
                useProfessionalAddress: $useProfessionalAddress
                annualReport: $annualReport
                bankAccountSetup: $bankAccountSetup
                trademarkRegister: $trademarkRegister
                useRegisteredAgent: $useRegisteredAgent
              }
            ) {
              itemList {
                name
                totalFee
              }
              totalFee
            }
          }
        `,
        variables() {
          return {
            package: this.customer.plan,
            state: this.customer.state,
            type: this.customer.type,
            useProfessionalAddress: this.customer.useAddressRent,
            annualReport: this.customer.annualReport,
            bankAccountSetup: this.customer.bankAccountSetup,
            trademarkRegister: this.customer.trademarkRegister,
            useRegisteredAgent: true,
          }
        },
        skip() {
          return !this.customer.state || !this.customer.type
        },
        fetchPolicy: 'cache-first',
      }
    },
  },
}
</script>
