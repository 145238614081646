<template>
  <div>
    <div class="mb-3">
      <label class="required-star">{{ $t('address.address') }}</label>
      <input
        type="text"
        class="form-control"
        v-model="customer[address1]"
        placeholder="1234 Main St"
        required
        :readonly="readonly=='true'"
      />
      <div class="invalid-feedback">Please enter valid address.</div>
    </div>

    <div class="mb-3">
      <label>{{ $t('address.address2') }}</label>
      <span class="text-muted">{{ $t('message.optional') }}</span>
      <input
        type="text"
        class="form-control"
        v-model="customer[address2]"
        placeholder="Apartment or suite"
        :readonly="readonly=='true'"
      />
    </div>
    <div class="row">
      <div class="col-lg-3 mb-3">
        <label class="required-star">{{ $t('address.country') }}</label>

        <input
          type="text"
          class="form-control"
          v-model="customer[country]"
          :readonly="readonly=='true'"
        />
        <div class="invalid-feedback">Country required.</div>
      </div>
      <div class="col-lg-3 mb-3">
        <label class="required-star">{{ $t('address.state') }}</label>
        <input
          type="text"
          class="form-control"
          v-model="customer[state]"
          :readonly="readonly=='true'"
        />
        <div class="invalid-feedback">State required.</div>
      </div>
      <div class="col-lg-3 mb-3">
        <label class="required-star">{{ $t('address.city') }}</label>
        <input
          type="text"
          class="form-control"
          v-model="customer[city]"
          placeholder
          required
          :readonly="readonly=='true'"
        />
        <div class="invalid-feedback">City required.</div>
      </div>
      <div class="col-lg-3 mb-3">
        <label class="required-star">{{ $t('address.zip') }}</label>
        <input
          type="text"
          class="form-control"
          v-model="customer[zip]"
          placeholder
          required
          :readonly="readonly=='true'"
        />
        <div class="invalid-feedback">Zip code required.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressInformation',
  props: [
    'customer',
    'companyAddr',
    'address1',
    'address2',
    'country',
    'state',
    'city',
    'zip',
    'readonly',
  ],
}
</script>

<style scoped>
.required-star::after {
  content: ' *';
  color: red;
}
</style>